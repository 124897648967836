import axios from "axios";
import { toast } from "react-toastify";
import history from "../../history";

const API = process.env.REACT_APP_API;

export const changePasswordRequest = (passwords, user) => async () => {
  const headers = {
    AUTHORIZATION: user.token,
  };
  try {
    const response = await axios.post(
      API + "/account/change_password",
      { ...passwords, email: user.email },
      { headers: headers }
    );
    if (response.status === 202) {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      history.push("/");
      toast.success("Password changed!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  } catch (e) {
    console.error("ERROR:", e);
  }
};
