/**
 * Imports
 */
import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import history from "../history";
import "./createNewConnection.css";
import axios from "axios";
import { useSelector } from "react-redux";

/**
 * Consts
 */
const API = process.env.REACT_APP_API;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    flexGrow: 1,
    maxWidth: "180px",
    maxHeight: "370px",
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  header: {
    marginTop: "2em",
  },
  subHeader: {
    marginBottom: "1em",
  },
  itemContainer: {
    margin: "1em",
  },
}));
const CONNECTION_TYPE = {
  SAML: "SAML",
  OAUTH: "OAUTH",
};

export default function CreateNewConnection() {
  const [spacing] = useState(2);
  const classes = useStyles();
  const [typeSelected, setTypeSelected] = useState("");
  const user = useSelector((state) => state.user.user);

  const headers = {
    AUTHORIZATION: user.token,
  };

  useEffect(() => {
    axios
      .get(`${API}/auth/default_connection/${user.districtID}`, {
        headers: headers,
      })
      .then((data) => {
        if (data.data.default_connection === "OAUTH") {
          setTypeSelected(CONNECTION_TYPE.OAUTH);
          // if (!skipRedirect) history.push("/new_connection/new_oauth_connection")
        } else if (data.data.default_connection === "SAML") {
          setTypeSelected(CONNECTION_TYPE.SAML);
          // if (!skipRedirect) history.push("/new_connection/new_sso_connection")
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const activeSetting = (card) => {
    return card === typeSelected ? "Active" : "";
  };

  function setDefaultConfiguration(connectionType) {
    console.log("Set default connection as " + connectionType);
    if (connectionType === "OAUTH") {
      // setTypeSelected(CONNECTION_TYPE.OAUTH);
      history.push("/new_connection/new_oauth_connection");
    } else if (connectionType === "SAML") {
      history.push("/new_connection/new_sso_connection");
      // setTypeSelected(CONNECTION_TYPE.SAML);
    }
  }
  console.log("connection type", typeSelected);
  return (
    <Container maxWidth="lg" className={classes.header}>
      <Typography align={"center"} variant="h4" className={classes.subHeader}>
        Please select an option from below to start.
      </Typography>
      <Typography align={"center"} variant="h6" className={classes.subHeader}>
        SAML: Security Assertion Markup Language <br />
        OAuth: Open Authorization
      </Typography>
      <Grid container className={classes.root} spacing={10}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={spacing}>
            <Grid item>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    className={classes.media}
                    src="https://cdn.rally.edanalytics.org/applauncher/SAML.png"
                    title="SAML"
                  />
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      setDefaultConfiguration(CONNECTION_TYPE.SAML);
                    }}
                  >
                    Configure
                  </Button>
                  <Typography variant={"caption"} style={{ color: "red" }}>
                    {activeSetting("SAML")}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
            <Grid item>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    className={classes.media}
                    src="https://cdn.rally.edanalytics.org/applauncher/OAuth.png"
                    title="SAML"
                  />
                </CardActionArea>
                <CardActions>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => {
                      setDefaultConfiguration(CONNECTION_TYPE.OAUTH);
                    }}
                  >
                    Configure
                  </Button>
                  <Typography variant={"caption"} style={{ color: "red" }}>
                    {activeSetting("OAUTH")}
                  </Typography>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
