import axios from "axios";
import { toast } from "react-toastify";
import cookie from "react-cookies";
import history from "../../history";

const API = process.env.REACT_APP_API;
export const LOG_USER_IN = "LOG_USER_IN";
export const LOG_OUT_USER = "LOG_OUT_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_ERROR_RESET = "LOGIN_ERROR_RESET";

export const logoutUser = () => {
  return {
    type: "LOG_USER_IN",
  };
};

export function loginUser(email, password, dispatch) {
  axios.defaults.headers.common["AUTHORIZATION"] = null;
  dispatch({
    type: "LOGIN_ERROR_RESET",
    payload: {
      error: false,
      message: "",
    },
  });
  cookie.save(
    "user",
    {
      districtID: false,
      eaAdmin: false,
      email: false,
      token: false,
      ssoUser: false,
      idpAdmin: false,
    },
    { path: "/" }
  );
  dispatch({
    type: "LOG_OUT_USER",
  });
  try {
    axios
      .post(API + "/auth/login", { email: email, password: password })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.error) {
            toast.error(res.data.message.errorMessage, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            dispatch({
              type: "LOGIN_ERROR",
              payload: {
                error: true,
                message: res.data.message.errorMessage,
              },
            });
          } else {
            if (res.data.message === "CHANGE_PASSWORD") {
              history.push(
                `/reset_password/${res.data.token}/${res.data.email}`,
                { from: "local login" }
              );
            } else {
              console.log("res", JSON.stringify(res));
              cookie.save(
                "user",
                {
                  districtID: res.data.districtID,
                  ssoUser: false,
                  eaAdmin: res.data.eaAdmin,
                  email: res.data.email,
                  token: res.data.token,
                  idpAdmin: res.data.idpAdmin,
                },
                { path: "/" }
              );
              axios.defaults.headers.common["AUTHORIZATION"] = res.data.token;
              dispatch({
                type: "LOG_USER_IN",
                payload: {
                  districtID: res.data.districtID,
                  eaAdmin: res.data.eaAdmin,
                  email: res.data.email,
                  token: res.data.token,
                  idpAdmin: res.data.idpAdmin,
                  ssoUser: false,
                },
              });
              // history.push('/')
              window.location.href = "/";
            }
          }
        } else {
          console.log("Incorrect Username and Password");
        }
      });
  } catch (error) {
    console.log(error);
  }
}

export function resetCookie() {
  cookie.save(
    "user",
    {
      districtID: null,
      eaAdmin: false,
      email: null,
      ssoUser: false,
      token: null,
    },
    { path: "/" }
  );
}

export function resetPassword(email, password, token, dispatch) {
  cookie.save(
    "user",
    {
      districtID: null,
      eaAdmin: false,
      email: null,
      ssoUser: false,
      token: null,
    },
    { path: "/" }
  );
  try {
    axios
      .post(API + "/auth/reset_password", {
        email: email,
        password: password,
        token,
      })
      .then((res) => {
        if (res.status === 200) {
          cookie.save(
            "user",
            {
              districtID: res.data.districtID,
              eaAdmin: res.data.eaAdmin,
              idpAdmin: res.data.idpAdmin,
              email: res.data.email,
              ssoUser: false,
              token: res.data.token,
            },
            { path: "/" }
          );
          axios.defaults.headers.common["AUTHORIZATION"] = res.data.token;
          dispatch({
            type: "LOG_USER_IN",
            payload: {
              districtID: res.data.districtID,
              eaAdmin: res.data.eaAdmin,
              email: res.data.email,
              token: res.data.token,
              idpAdmin: res.data.idpAdmin,
              ssoUser: false,
            },
          });
          window.location.href = "/";
        } else {
          console.log("Incorrect Username and Password");
        }
      })
      .catch((err) => {
        console.log("Incorrect Username and Password", err);
      });
  } catch (error) {
    console.log(error);
    //dispatch(loginFailure());
  }
}

export function createUser(newUserObject, token) {
  const headers = {
    AUTHORIZATION: token,
  };
  try {
    axios
      .post(
        API + "/auth/create_user",
        {
          email: newUserObject.email,
          districtID: newUserObject.districtID,
          eaAdmin: newUserObject.eaAdmin,
          idpAdmin: newUserObject.idpAdmin,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.status === "ok") {
          toast.success("User Created!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        switch (err) {
          default:
            toast.error("User Not Created", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
            break;
        }
        return false;
      });
    return true;
  } catch (error) {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
}
