import {
  ADD_PERMISSIONS_OBJECT,
  UPDATE_PERMISSIONS_OBJECT,
} from "../../actions/Permissions/Permissions";

const initialState = {
  applications: [],
  permissions: [],
};

export default function permissions(state = initialState, action) {
  switch (action.type) {
    case ADD_PERMISSIONS_OBJECT:
      return {
        ...state,
        permissions: action.payload.permissions,
        applications: action.payload.applications,
      };
    case UPDATE_PERMISSIONS_OBJECT:
      return {
        ...state,
      };
    default:
      return state;
  }
}
