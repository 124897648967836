import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";

import {
  getApplications,
  getDistrictsFromIDP,
} from "../actions/Applications/Applications";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
}));

export default function AssumeDistrict() {
  const user = useSelector((state) => state.user.user);
  const applications = useSelector((state) => state.applications.applications);
  const districts = useSelector((state) => state.districts.districts);
  const dispatch = useDispatch();
  useEffect(() => {
    getApplications(user, dispatch);
  }, []);
  const classes = useStyles();
  const [url, setUrl] = React.useState({
    location: null,
  });
  const [district, setDistrict] = React.useState({
    districtID: null,
  });
  const handleChangeApplication = (event, newValue) => {
    getDistrictsFromIDP({
      user,
      dispatch,
      assumeUserDistrictUrl: newValue.applicationAssumeUrlDistrict,
    });
    setUrl({
      location: newValue.tokenIntakeURL,
    });
  };
  const handleChangeDistrict = (event, newValue) => {
    setDistrict({
      districtID: newValue.district_id,
    });
  };
  const encodeUserObject = (user) => {
    let user_temp = user;
    user_temp.districtID = district.districtID;
    user_temp.district_viewer = true;
    return btoa(JSON.stringify(user_temp));
  };
  return (
    <Grid
      container
      alignItems={"flex-start"}
      justify={"center"}
      className={classes.root}
      style={{ marginTop: "5em" }}
    >
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              id="combo-box-demo"
              options={applications}
              getOptionLabel={(option) => option.applicationName}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                handleChangeApplication(event, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Applications"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              id="combo-box-demo"
              options={districts}
              getOptionLabel={(option) => option.district_name}
              style={{ width: 300 }}
              onChange={(event, newValue) => {
                handleChangeDistrict(event, newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Districts" variant="outlined" />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={2}>
          <FormControl className={classes.formControl}>
            <Button
              style={{ width: 300, height: "4em" }}
              variant="contained"
              color="primary"
              onClick={() => {
                window.open(
                  `${url.location}${encodeUserObject(user)}`,
                  "_blank"
                );
              }}
            >
              Launch
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
