import { GET_USERS_FROM_DISTRICT_SELECTION } from "../../actions/ResendingCredentials/ResendingCredentials";

const initialState = {
  usersFromDistrictSelected: [],
};

export default function resendingCredentials(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_FROM_DISTRICT_SELECTION:
      return {
        ...state,
        usersFromDistrictSelected: action.payload,
      };
    default:
      return state;
  }
}
