import { resetAllDistrictUsers } from "../Applications/Applications";
import axios from "axios";

const API = process.env.REACT_APP_API;
export const SET_USER_PERMISSION_DISTRICT_SELECTION =
  "SET_USER_PERMISSION_DISTRICT_SELECTION";
export const SET_USER_PERMISSION_USER_SELECTION =
  "SET_USER_PERMISSION_USER_SELECTION";
export const RESET_USER_PERMISSION = "RESET_USER_PERMISSION";
export const RESET_USER_PERMISSION_USER_SELECTION =
  "RESET_USER_PERMISSION_USER_SELECTION";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const UPDATE_USER_PERMISSION_OBJECT = "UPDATE_USER_PERMISSION_OBJECT";

export async function setUserPermissionDistrictSelection(
  user,
  selectedDistrict,
  dispatch,
  selectedUser = undefined
) {
  const headers = {
    AUTHORIZATION: user.token,
  };
  await axios
    .post(
      `${API}/auth/admin/get_district_users`,
      {
        email: user.email,
        districtID: selectedDistrict.district_id,
      },
      { headers: headers }
    )
    .then((res) => {
      const updateSelectUser = res.data.find((obj) => {
        return (
          obj.email === selectedUser?.email &&
          obj.districtID === selectedUser?.districtID
        );
      });
      dispatch({
        type: "SET_USER_PERMISSION_DISTRICT_SELECTION",
        payload: {
          selectedDistrict,
          selectedUser: updateSelectUser,
          userList: res.data,
        },
      });
    });
}

export async function setUserPermissionUserSelection(selectedUser, dispatch) {
  const userPermissionsObject = {
    district_admin: {
      displayText: "District Admin",
      selectUserKey: "district_admin",
      booleanValue: selectedUser["district_admin"],
    },
    eaAdmin: {
      displayText: "EA Admin",
      selectUserKey: "eaAdmin",
      booleanValue: selectedUser["eaAdmin"],
    },
    district_viewer: {
      displayText: "District Viewer",
      selectUserKey: "district_viewer",
      booleanValue: selectedUser["district_viewer"],
    },
    idpAdmin: {
      displayText: "IDP Admin",
      selectUserKey: "idpAdmin",
      booleanValue: selectedUser["idpAdmin"],
    },
  };
  dispatch({
    type: "SET_USER_PERMISSION_USER_SELECTION",
    payload: {
      selectedUser,
      userPermissionsObject,
    },
  });
}

export function updateUserPermissionObject(
  userPermissionsObject,
  individualPermission,
  dispatch
) {
  const { selectUserKey } = individualPermission;
  const userPermissionObjectCopy = userPermissionsObject;
  userPermissionObjectCopy[selectUserKey].booleanValue =
    !userPermissionObjectCopy[selectUserKey].booleanValue;
  dispatch({
    type: "UPDATE_USER_PERMISSION_OBJECT",
    payload: userPermissionObjectCopy,
  });
}

export function resetUserPermissionUserSelection(dispatch) {
  dispatch({
    type: "RESET_USER_PERMISSION_USER_SELECTION",
  });
}

export function resetUserPermission(dispatch) {
  dispatch({
    type: "RESET_USER_PERMISSION",
  });
  resetAllDistrictUsers(dispatch);
}
