import { toast } from "react-toastify";

const pxToVw = (pxFontSize) => {
  const calcNum = (pxFontSize / 1440) * 100;
  const number = calcNum >= pxFontSize ? pxFontSize : `${calcNum}vw`;
  return number;
};

const pxToVwTablet = (pxFontSize) => {
  const calcNum = (pxFontSize / 768) * 100;
  const number = calcNum >= pxFontSize ? pxFontSize : `${calcNum}vw`;
  return number;
};

const pxToVwMobile = (pxFontSize) => {
  const calcNum = (pxFontSize / 320) * 100;
  const number = calcNum >= pxFontSize ? pxFontSize : `${calcNum}vw`;
  return number;
};

const toastMessage = (type, message) => {
  if (type === "success") {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  } else if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
};

export { pxToVw, pxToVwTablet, pxToVwMobile, toastMessage };
