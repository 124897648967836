import React from "react";
import { Link } from "react-router-dom";

export const customToastRedirect = (route) => (
  <div>
    <p>
      Password not required. Sign in with your organization at{" "}
      <Link to={"/login"}>{route}</Link>
    </p>
  </div>
);
