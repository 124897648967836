import React from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Link } from "react-router-dom";
import ListSubheader from "@material-ui/core/ListSubheader";
import HomeIcon from "@material-ui/icons/Home";
import AddIcon from "@material-ui/icons/Add";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import GavelIcon from "@material-ui/icons/Gavel";
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";
import EditIcon from "@material-ui/icons/Edit";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { toggleDrawerState } from "../actions/Menu/Menu";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  noLinkColor: {
    textDecoration: "none",
    color: "inherit",
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuOpen = useSelector((state) => state.menu.sideDrawerOpen);
  const anchorPoint = useSelector((state) => state.menu.anchorPoint);
  const user = useSelector((state) => state.user.user);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    dispatch(toggleDrawerState(open));
    //setState({ ...state, [anchor]: open });
  };
  const adminList = () => {
    if (user.eaAdmin) {
      return (
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              EA Admin Actions
            </ListSubheader>
          }
        >
          <Link
            className={classes.noLinkColor}
            to={"/new_user"}
            onClick={toggleDrawer(false)}
          >
            <ListItem button>
              <ListItemIcon>
                <PersonAddIcon />
              </ListItemIcon>
              <ListItemText primary={"Add New Admin User"} />
            </ListItem>
          </Link>
          <Link
            className={classes.noLinkColor}
            to={"/new_app"}
            onClick={toggleDrawer(false)}
          >
            <ListItem button>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={"Add New Application"} />
            </ListItem>
          </Link>
          <Link
            className={classes.noLinkColor}
            to={"/assume_user"}
            onClick={toggleDrawer(false)}
          >
            <ListItem button>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary={"Assume User"} />
            </ListItem>
          </Link>
          <Link
            className={classes.noLinkColor}
            to={"/permissions"}
            onClick={toggleDrawer(false)}
          >
            <ListItem button>
              <ListItemIcon>
                <GavelIcon />
              </ListItemIcon>
              <ListItemText primary={"App Permissions"} />
            </ListItem>
          </Link>
          <Link
            className={classes.noLinkColor}
            to={"/user_permissions"}
            onClick={toggleDrawer(false)}
          >
            <ListItem button>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={"User Permissions"} />
            </ListItem>
          </Link>
        </List>
      );
    }
  };

  const idpAdmin = () => {
    if (user.eaAdmin || user.idpAdmin) {
      return (
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Actions
            </ListSubheader>
          }
        >
          {/*<Link className={classes.noLinkColor} to={"/new_sso_connection"} onClick={toggleDrawer(false)}>*/}
          {/*    <ListItem button>*/}
          {/*        <ListItemIcon>*/}
          {/*            <AssignmentTurnedInIcon />*/}
          {/*        </ListItemIcon>*/}
          {/*        <ListItemText primary={"New SAML Connection"} />*/}
          {/*    </ListItem>*/}
          {/*</Link>*/}
          {/*<Link className={classes.noLinkColor} to={"/new_oauth_connection"} onClick={toggleDrawer(false)}>*/}
          {/*    <ListItem button>*/}
          {/*        <ListItemIcon>*/}
          {/*            <AssignmentTurnedInIcon />*/}
          {/*        </ListItemIcon>*/}
          {/*        <ListItemText primary={"New Oauth Connection"} />*/}
          {/*    </ListItem>*/}
          {/*</Link>*/}
          <Link
            className={classes.noLinkColor}
            to={"/new_connection"}
            onClick={toggleDrawer(false)}
          >
            <ListItem button>
              <ListItemIcon>
                <SettingsInputCompositeIcon />
              </ListItemIcon>
              <ListItemText primary={"New SSO Connection"} />
            </ListItem>
          </Link>
        </List>
      );
    }
  };

  const accountManagement = () => {
    if (user.eaAdmin || user.idpAdmin) {
      return (
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader-account">
              Account
            </ListSubheader>
          }
        >
          <Link
            className={classes.noLinkColor}
            to={"/change_password"}
            onClick={toggleDrawer(false)}
          >
            <ListItem button>
              <ListItemIcon>
                <LockOpenIcon />
              </ListItemIcon>
              <ListItemText primary={"Change Password"} />
            </ListItem>
          </Link>
        </List>
      );
    }
  };

  const userPages = () => {
    return (
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Pages
          </ListSubheader>
        }
      >
        <Link
          className={classes.noLinkColor}
          to={"/"}
          onClick={toggleDrawer(false)}
        >
          <ListItem button>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"App Launcher"} />
          </ListItem>
        </Link>
      </List>
    );
  };

  const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={() => {
        toggleDrawer(false);
      }}
      onKeyDown={toggleDrawer(false)}
    >
      {adminList()}
      {idpAdmin()}
      {accountManagement()}
      {userPages()}
    </div>
  );

  return (
    <div>
      <React.Fragment key={anchorPoint}>
        <Drawer
          anchor={anchorPoint}
          open={menuOpen}
          onClose={toggleDrawer(false)}
        >
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
