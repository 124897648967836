const userPermissionsStyle = (theme) => ({
  selectorContainer: {
    paddingTop: "5%",
  },
  selectorSubContainer: {
    paddingTop: "3%",
  },
  toggleContainerMain: {
    paddingBottom: 1,
  },
  toggleContainer: {
    width: 400,
    border: "1px solid rgba(0,0,0, 0.5)",
    borderRadius: 3,
  },
  displayText: {
    paddingLeft: 10,
  },
  deleteButton: {
    minWidth: 168,
  },
  resendingCredentialsButton: {
    paddingTop: 20,
    width: 168,
  },
  buttonGrid: {
    maxWidth: 400,
    paddingTop: "5%",
  },
  addUserButton: {
    padding: 15,
  },
});

export default userPermissionsStyle;
