import axios from "axios";
import { toast } from "react-toastify";
import { customToastRedirect } from "../../Helpers/Login/RedirectToLogin";
import history from "../../history";

const API = process.env.REACT_APP_API;

export const forgotPassword = (email) => async () => {
  try {
    const response = await axios.post(API + "/account/forgot_password", {
      email: email,
    });
    if (response.status === 200) {
      toast.success("Sent! Check your email.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (response.status === 203) {
      toast.info(customToastRedirect(response.data.message), {
        position: "top-right",
        autoClose: 9000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  } catch (e) {
    toast.error(e, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
};

export const confirmForgotPassword =
  (email, password, code, dispatch) => async () => {
    try {
      const response = await axios.post(
        API + "/account/confirm_forgot_password",
        { code: code, email: email, password: password }
      );
      if (response.status === 200) {
        history.push("/local_login");
        toast.success("Password Changed! Please sign in.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (e) {
      toast.error(e, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };
