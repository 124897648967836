import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckIcon from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  darkGrey,
  standardRed,
  standardGreen,
} from "../../assets/globalStyles";

const useStyles = makeStyles((Theme) => ({
  typography: {
    fontSize: 13,
    color: darkGrey,
  },
  notIcon: {
    display: "flex",
    alignItems: "center",
    color: standardRed,
  },
  checkIcon: {
    display: "flex",
    alignItems: "center",
    color: standardGreen,
  },
}));

export default function PasswordValidation({
  password1,
  password2,
  validationStatus,
  setValidationStatus,
}) {
  const classes = useStyles();

  // Regex for password validation
  const regex = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const capitalRegex = /[A-Z]/;
  const numericRegex = /[0-9]/;

  const validationChange = (property, bool) => {
    setValidationStatus({
      ...validationStatus,
      [property]: bool,
    });
  };

  useEffect(() => {
    // Change validation status for length rule
    if (password1.length >= 10 && !validationStatus.length) {
      validationChange("length", true);
    } else if (password1.length < 10 && validationStatus.length) {
      validationChange("length", false);
    }
    // special character rule
    if (regex.test(password1) && !validationStatus.special) {
      validationChange("special", true);
    } else if (!regex.test(password1) && validationStatus.special) {
      validationChange("special", false);
    }
    // capital character rule
    if (capitalRegex.test(password1) && !validationStatus.capital) {
      validationChange("capital", true);
    } else if (!capitalRegex.test(password1) && validationStatus.capital) {
      validationChange("capital", false);
    }
    // numeric character rule
    if (numericRegex.test(password1) && !validationStatus.numeric) {
      validationChange("numeric", true);
    } else if (!numericRegex.test(password1) && validationStatus.numeric) {
      validationChange("numeric", false);
    }
  }, [password1]);

  useEffect(() => {
    if (
      password2.length > 0 &&
      password1 === password2 &&
      !validationStatus.match
    ) {
      validationChange("match", true);
    } else if (password1 !== password2 && validationStatus.match) {
      validationChange("match", false);
    }
  }, [password1, password2]);

  return (
    <Grid item container xs={12} direction={"row"} alignItems={"center"}>
      {validationStatus.length ? (
        <Grid item xs={1} className={classes.checkIcon}>
          <CheckIcon />
        </Grid>
      ) : (
        <Grid item xs={1} className={classes.notIcon}>
          <NotInterestedIcon />
        </Grid>
      )}
      <Grid item xs={11}>
        <Typography className={classes.typography}>
          Password length is >= 10
        </Typography>
      </Grid>
      <Grid item container xs={12} direction={"row"} alignItems={"center"}>
        {validationStatus.special ? (
          <Grid item xs={1} className={classes.checkIcon}>
            <CheckIcon />
          </Grid>
        ) : (
          <Grid item xs={1} className={classes.notIcon}>
            <NotInterestedIcon />
          </Grid>
        )}
        <Grid item xs={11}>
          <Typography className={classes.typography}>
            Password has one special character
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} direction={"row"} alignItems={"center"}>
        {validationStatus.capital ? (
          <Grid item xs={1} className={classes.checkIcon}>
            <CheckIcon />
          </Grid>
        ) : (
          <Grid item xs={1} className={classes.notIcon}>
            <NotInterestedIcon />
          </Grid>
        )}
        <Grid item xs={11}>
          <Typography className={classes.typography}>
            Password has one capital character
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} direction={"row"} alignItems={"center"}>
        {validationStatus.numeric ? (
          <Grid item xs={1} className={classes.checkIcon}>
            <CheckIcon />
          </Grid>
        ) : (
          <Grid item xs={1} className={classes.notIcon}>
            <NotInterestedIcon />
          </Grid>
        )}
        <Grid item xs={11}>
          <Typography className={classes.typography}>
            Password has one numeric character
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} direction={"row"} alignItems={"center"}>
        {validationStatus.match ? (
          <Grid item xs={1} className={classes.checkIcon}>
            <CheckIcon />
          </Grid>
        ) : (
          <Grid item xs={1} className={classes.notIcon}>
            <NotInterestedIcon />
          </Grid>
        )}
        <Grid item xs={11}>
          <Typography className={classes.typography}>
            Passwords match
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function passwordErrors(statusObj) {
  const errorLog = [];
  if (!statusObj.length) {
    errorLog.push("Password is less than 10 characters long!");
  }
  if (!statusObj.special) {
    errorLog.push("Password does not have a special character!");
  }
  if (!statusObj.capital) {
    errorLog.push("Password does not have a capital character!");
  }
  if (!statusObj.numeric) {
    errorLog.push("Password does not have a numeric character!");
  }
  if (!statusObj.match) {
    errorLog.push("Passwords do not match!");
  }
  return errorLog;
}
