import {
  FETCH_IDP_LOGINS,
  FETCH_ALL_IDS,
} from "../../actions/IDPLogins/IDPLogins";

const initialState = {
  idps: [],
  district_ids: [],
};

export default function idplogins(state = initialState, action) {
  switch (action.type) {
    case FETCH_IDP_LOGINS:
      return {
        ...state,
        idps: action.payload,
      };
    case FETCH_ALL_IDS:
      return {
        ...state,
        district_ids: action.payload,
      };
    default:
      return state;
  }
}
