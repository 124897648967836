import {
  LOG_USER_IN,
  LOG_OUT_USER,
  LOGIN_ERROR,
  LOGIN_ERROR_RESET,
} from "../../actions/User/User";

const initialState = {
  user: {
    email: null,
    token: null,
    eaAdmin: false,
    idpAdmin: false,
    districtID: null,
    ssoUser: false,
  },
  error: {
    message: "",
    error: false,
  },
};

export default function menu(state = initialState, action) {
  switch (action.type) {
    case LOG_USER_IN:
      return {
        ...state,
        user: {
          email: action.payload.email,
          token: action.payload.token,
          eaAdmin: action.payload.eaAdmin,
          districtID: action.payload.districtID,
          idpAdmin: action.payload.idpAdmin,
          ssoUser: action.payload.ssoUser,
        },
      };
    case LOG_OUT_USER:
      return {
        ...state,
        user: {
          email: null,
          token: false,
          eaAdmin: false,
          idpAdmin: false,
          districtID: null,
          ssoUser: false,
        },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        error: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case LOGIN_ERROR_RESET:
      return {
        ...state,
        error: {
          message: "",
          error: false,
        },
      };
    default:
      return state;
  }
}
