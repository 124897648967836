import {
  FETCH_ALL_DISTRICTS,
  FETCH_ALL_DISTRICT_USERS,
  RESET_ALL_DISTRICT_USERS,
} from "../../actions/Applications/Applications";

const initialState = {
  districts: [],
  users: {
    init: false,
    list: [],
  },
};

export default function districts(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_DISTRICTS:
      return {
        ...state,
        districts: action.payload,
      };
    case FETCH_ALL_DISTRICT_USERS:
      return {
        ...state,
        users: {
          init: true,
          list: action.payload,
        },
      };
    case RESET_ALL_DISTRICT_USERS:
      return {
        ...state,
        users: {
          init: false,
          list: [],
        },
      };
    default:
      return state;
  }
}
