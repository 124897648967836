import { OPEN_SIDE_DRAWER } from "../../actions/Menu/Menu";

const initialState = {
  sideDrawerOpen: false,
  anchorPoint: "left",
};

export default function menu(state = initialState, action) {
  switch (action.type) {
    case OPEN_SIDE_DRAWER:
      return {
        ...state,
        sideDrawerOpen: action.payload,
      };
    default:
      return state;
  }
}
