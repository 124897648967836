import React, { useEffect } from "react";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import history from "../history";
import { useDispatch } from "react-redux";
import axios from "axios";
import cookie from "react-cookies";

const API = process.env.REACT_APP_API;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "1em",
  },
  paper: {
    height: 75,
    width: 75,
    textAlign: "center",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function TokenValidation(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const token = props.match.params.token;
  const id = props.match.params.id;

  if (!token) {
    window.location.href = "/login";
  }

  useEffect(() => {
    const headers = {
      AUTHORIZATION: token,
    };
    axios
      .post(
        API + "/auth/sso/verify_token",
        {},
        {
          headers: headers,
        }
      )
      .then((data) => {
        if (data.data.verified) {
          dispatch({
            type: "LOG_USER_IN",
            payload: {
              email: data.data.email,
              token: token,
              eaAdmin: data.data.user_data.eaAdmin,
              idpAdmin: false,
              ssoUser: true,
              districtID: id,
            },
          });
          cookie.save(
            "user",
            {
              email: data.data.email,
              token: token,
              eaAdmin: data.data.user_data.eaAdmin,
              idpAdmin: false,
              ssoUser: true,
              districtID: id,
            },
            { path: "/" }
          );

          history.push("/");
        } else {
          window.location.href = "/login";
        }
      })
      .catch((error) => {
        console.log("Error", error);
        dispatch({
          type: "LOG_USER_IN",
          payload: {
            email: null,
            token: null,
            eaAdmin: false,
            idpAdmin: false,
            ssoUser: true,
            districtID: null,
          },
        });
        cookie.save(
          "user",
          {
            email: null,
            token: null,
            eaAdmin: false,
            idpAdmin: false,
            ssoUser: true,
            districtID: null,
          },
          { path: "/" }
        );
        window.location.href = "/login";
      });
  });

  return (
    <Container>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={4}>
            <Typography component="h1" variant="h5">
              Setting/Validating Token
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
