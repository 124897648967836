import {
  pxToVw,
  pxToVwMobile,
  pxToVwTablet,
} from "../../Helpers/globalHelpers";
import {
  frederikRegular,
  ibmPlexSans,
  iconBlue,
  iconTeal,
  white,
} from "../globalStyles";

const selectDistrictStyle = (theme) => ({
  root: {
    maxHeight: "1057px",
    height: pxToVw(1057),
    width: "100%",
    backgroundColor: iconBlue,
    [theme.breakpoints.up("xl")]: {
      height: 1057,
    },
    [theme.breakpoints.down("md")]: {
      height: pxToVwTablet(1024),
    },
    [theme.breakpoints.down("sm")]: {
      height: 568,
    },
  },
  displayText: {
    backgroundColor: white,
    width: pxToVw(365),
    // height: pxToVw(60),
    color: iconTeal,
    fontFamily: frederikRegular,
    fontSize: pxToVw(25),
    [theme.breakpoints.up("xl")]: {
      width: 365,
      // height: 60,
      fontSize: 25,
    },
    [theme.breakpoints.down("md")]: {
      width: pxToVwTablet(373),
      // height: pxToVwTablet(50),
      fontSize: pxToVwTablet(25),
    },
    [theme.breakpoints.down("sm")]: {
      width: 300,
      // height: 50,
      fontSize: 25,
    },
  },
  descriptionText: {
    color: white,
    fontFamily: frederikRegular,
    fontSize: pxToVw(20),
    [theme.breakpoints.up("xl")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: pxToVwTablet(18),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      paddingRight: pxToVwMobile(10),
      paddingLeft: pxToVwMobile(10),
    },
  },
  buttonGrid: {
    paddingTop: pxToVw(10),
    [theme.breakpoints.up("xl")]: {
      paddingTop: 10,
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: pxToVwTablet(10),
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 10,
    },
  },
  button: {
    cursor: "pointer",
    display: "block",
    outline: "none",
    border: "none",
    background: iconBlue,
    fontFamily: ibmPlexSans,
    fontSize: pxToVw(21),
    color: white,
    width: pxToVw(365),
    height: pxToVw(60),
    [theme.breakpoints.up("xl")]: {
      fontSize: 21,
      height: 60,
      width: 365,
    },
    [theme.breakpoints.down("md")]: {
      width: pxToVwTablet(375),
      height: pxToVwTablet(50),
      fontSize: pxToVwTablet(21),
    },
    [theme.breakpoints.down("sm")]: {
      width: 301,
      height: 50,
      fontSize: 25,
    },
  },
  menuItem: {
    color: iconTeal,
    fontFamily: frederikRegular,
    fontSize: pxToVw(25),
    [theme.breakpoints.up("xl")]: {
      fontSize: 25,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: pxToVwTablet(21),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 21,
    },
  },
  downArrowItem: {
    paddingRight: pxToVw(20),
    [theme.breakpoints.up("xl")]: {
      paddingRight: 20,
    },
  },
  downArrow: {
    width: pxToVw(23),
    [theme.breakpoints.up("xl")]: {
      width: 23,
    },
  },
  test: {
    backgroundColor: "white",
    [theme.breakpoints.up("xl")]: {
      width: 365,
    },
    [theme.breakpoints.down("md")]: {
      width: pxToVwTablet(375),
    },
    [theme.breakpoints.down("sm")]: {
      width: 301,
    },
    color: "#0FB9A3",
    fontFamily: "Frederik-Regular",
  },
});

export default selectDistrictStyle;
