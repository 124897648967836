import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//Actions
import { loginUser, resetCookie } from "../actions/User/User";
import { forgotPassword } from "../actions/Account/ForgotPassword";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    marginTop: 20,
    color: "primary",
  },
}));

export default function Login() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPasswordOpen, setForgotPasswordOpen] = React.useState(false);
  let userItems = {
    email: "",
    password: "",
  };

  const forgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  useEffect(() => {
    resetCookie();
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Local Login
        </Typography>
        <form
          className={classes.form}
          noValidate
          autoComplete={false}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  userItems.email = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="password"
                onInput={(e) => {
                  setPassword(e.target.value);
                }}
                //onChange={(e) => {
                //    userItems.password = e.target.value;
                //}}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              loginUser(email, password, dispatch);
            }}
          >
            Login
          </Button>
        </form>
        <Link
          component="button"
          className={classes.link}
          onClick={() => setForgotPasswordOpen(true)}
        >
          <Typography>Forgot password?</Typography>
        </Link>
        <Dialog open={forgotPasswordOpen} onClose={forgotPasswordClose}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We'll send you a link to reset your password.
              <br />
              Enter the email associated with this account:
            </DialogContentText>
            <TextField
              autoFocus
              id={"email"}
              label={"Email Address"}
              defaultValue={email}
              type={"email"}
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value);
                userItems.email = e.target.value;
              }}
            />
            <DialogActions>
              <Button onClick={() => forgotPasswordClose()} color={"primary"}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  dispatch(forgotPassword(email, dispatch));
                  forgotPasswordClose();
                }}
                color={"primary"}
              >
                Continue
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    </Container>
  );
}
