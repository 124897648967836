import React, { useEffect, useLayoutEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getApplications } from "../actions/Applications/Applications";
import { useDispatch, useSelector } from "react-redux";
import appLauncherStyle from "../assets/jss/appLauncherStyle";
import axios from "axios";
import cookie from "react-cookies";
const API = process.env.REACT_APP_API;

export default function AppLauncher() {
  const user = useSelector((state) => state.user.user);
  const applications = useSelector((state) => state.applications.applications);
  const dispatch = useDispatch();
  const classes = makeStyles((theme) => appLauncherStyle(theme))();
  // console.log("user", user);

  useLayoutEffect(() => {
    const headers = {
      AUTHORIZATION: user.token,
    };
    console.log("headers", headers);
    axios
      .post(
        API + "/auth/verify_token",
        {},
        {
          headers: headers,
        }
      )
      .then((data) => {
        switch (data.statusCode) {
          case 200:
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        dispatch({
          type: "LOG_USER_IN",
          payload: {
            email: null,
            token: null,
            eaAdmin: false,
            idpAdmin: false,
            districtID: null,
          },
        });
        cookie.save(
          "user",
          {
            email: null,
            token: null,
            eaAdmin: false,
            idpAdmin: false,
            districtID: null,
          },
          { path: "/" }
        );
        window.location.href = "/login";
      });
  }, []);

  useEffect(() => {
    getApplications(user, dispatch);
  }, []);

  const encodeUserObject = (user) => {
    let temp_object = user;
    temp_object.districtID = user.districtID.split(".")[0];
    return btoa(JSON.stringify(temp_object));
  };

  return (
    <Grid
      container
      alignItems={"flex-start"}
      justify={"center"}
      className={classes.root}
    >
      {applications.length > 0 ? (
        applications.map((value, index) => (
          <Grid
            item
            className={classes.itemContainer}
            onClick={() => {
              window.open(
                `${value.tokenIntakeURL}${encodeUserObject(user)}${
                  value.tokenintakeurl_2 ? value.tokenintakeurl_2 : ""
                }`,
                "_blank"
              );
            }}
          >
            <img src={value.applicationLogo} />
          </Grid>
        ))
      ) : (
        <Grid
          container
          item
          alignItems={"flex-start"}
          justify={"center"}
          className={classes.emailContainer}
        >
          email: {user.email}
        </Grid>
      )}
    </Grid>
  );
}
