import React from "react";
import Container from "@material-ui/core/Container/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";

import { createApplications } from "../actions/Applications/Applications";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AddApp() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);

  let newApplication = {
    applicationName: "",
    tokenIntakeURL: "",
    applicationLogo: "",
    exclusiveTo: "",
    tokenintakeurl_2: "",
  };
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          New SSO Application <AddIcon />
        </Typography>
        <form
          className={classes.form}
          noValidate
          autoComplete={false}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                name="applicationName"
                variant="outlined"
                required
                fullWidth
                id="applicationName"
                label="Application Name"
                autoFocus
                onChange={(e) => {
                  newApplication.applicationName = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="tokenIntakeURL"
                variant="outlined"
                required
                fullWidth
                id="tokenIntakeURL"
                label="Token Intake URL"
                autoFocus
                onChange={(e) => {
                  newApplication.tokenIntakeURL = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="tokenintakeurl_2"
                variant="outlined"
                fullWidth
                id="tokenintakeurl_2"
                label="Token Intake URL 2 *if Needed"
                autoFocus
                onChange={(e) => {
                  newApplication.tokenintakeurl_2 = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="applicationLogo"
                variant="outlined"
                fullWidth
                id="applicationLogo"
                label="Application Logo *only if one has been made"
                autoFocus
                onChange={(e) => {
                  newApplication.applicationLogo = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                name="exclusiveTo"
                variant="outlined"
                fullWidth
                id="exclusiveTo"
                label="Exclusive to an IDP? *Example is SI+ for LA"
                autoFocus
                onChange={(e) => {
                  newApplication.exclusiveTo = e.target.value;
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              dispatch(createApplications(newApplication, user.token));
            }}
          >
            Create App
          </Button>
        </form>
      </div>
    </Container>
  );
}
