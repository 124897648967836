import axios from "axios";
import { toast } from "react-toastify";
import history from "../../history";

const API = process.env.REACT_APP_API;
export const FETCH_ALL_APPLICATIONS = "FETCH_ALL_APPLICATIONS";
export const ADD_APPLICATIONS = "ADD_APPLICATIONS";
export const FETCH_ALL_DISTRICTS = "FETCH_ALL_DISTRICTS";
export const FETCH_ALL_DISTRICT_USERS = "FETCH_ALL_DISTRICT_USERS";
export const RESET_ALL_DISTRICT_USERS = "RESET_ALL_DISTRICT_USERS";
export function getDistrictsFromIDP({ user, dispatch, assumeUserDistrictUrl }) {
  const headers = {
    AUTHORIZATION: user.token,
  };
  try {
    axios
      .get(assumeUserDistrictUrl, {
        headers: headers,
      })
      .then((res) => {
        dispatch({
          type: "FETCH_ALL_DISTRICTS",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  } catch (error) {
    console.log("CATCH error", error);
  }
}

export function resetAllDistrictUsers(dispatch) {
  dispatch({
    type: "RESET_ALL_DISTRICT_USERS",
    payload: [],
  });
}

export async function getDistrictUsersFromIDP({
  user,
  district_id,
  dispatch,
  assumeUserUserUrl,
}) {
  dispatch({
    type: "RESET_ALL_DISTRICT_USERS",
    payload: [],
  });
  const headers = {
    AUTHORIZATION: user.token,
  };
  try {
    const { data } = await axios.post(
      assumeUserUserUrl,
      {
        district_id: district_id,
      },
      {
        headers: headers,
      }
    );
    dispatch({
      type: "FETCH_ALL_DISTRICT_USERS",
      payload: data,
    });
  } catch (error) {
    console.log("CATCH error", error);
  }
}

export function getApplications(user, dispatch) {
  // console.log("user", user)
  const headers = {
    AUTHORIZATION: user.token,
  };
  try {
    axios
      .post(
        `${API}/application/get_applications/${user.districtID}`,
        {},
        {
          headers: headers,
        }
      )
      .then((res) => {
        dispatch({
          type: "FETCH_ALL_APPLICATIONS",
          payload: res.data.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  } catch (error) {
    console.log("CATCH error", error);
  }
}

export const createApplications =
  (newApplication, token, dispatch) => async (dispatch) => {
    const headers = {
      AUTHORIZATION: token,
    };
    try {
      await axios.post(
        API + "/application/create_application",
        {
          applicationName: newApplication.applicationName,
          tokenIntakeURL: newApplication.tokenIntakeURL,
          applicationLogo: newApplication.applicationLogo,
          exclusiveTo: newApplication.exclusiveTo,
          tokenintakeurl_2: newApplication.tokenintakeurl_2,
        },
        {
          headers: headers,
        }
      );
      toast.success("Application Created!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      dispatch({
        type: "ADD_APPLICATIONS",
        payload: {
          applicationName: newApplication.applicationName,
          tokenIntakeURL: newApplication.tokenIntakeURL,
          applicationLogo: newApplication.applicationLogo,
          exclusiveTo: newApplication.exclusiveTo,
          tokenintakeurl_2: newApplication.tokenintakeurl_2,
        },
      });
      history.push("/");
    } catch (error) {
      console.log("CATCH error", error);
    }
  };
