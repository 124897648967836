import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import { getIDPLogins } from "../actions/IDPLogins/IDPLogins";
import { useDispatch, useSelector } from "react-redux";
import selectDistrictStyle from "../assets/jss/selectDistrictStyle";
import { frederikRegular, iconTeal } from "../assets/globalStyles";
import { pxToVw } from "../Helpers/globalHelpers";

const oAuthSelectorStyleOverride = {
  control: (base) => ({
    ...base,
    background: "white",
    borderRadius: 0,
    outline: "none",
    border: 0,
    borderColor: "none",
    boxShadow: "none",
    "&:hover": {
      outline: "none",
      border: 0,
      borderColor: "none",
      boxShadow: "none",
    },
  }),
  container: (base) => ({
    ...base,
    background: "transparent",
    outline: "none",
    border: 0,
    height: 60,
    "&:hover": {
      outline: "none",
      border: 0,
    },
    "&:focus": {
      outline: "none",
      border: 0,
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: iconTeal,
    ":hover": {
      color: iconTeal,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    whiteSpace: "normal",
    background: "transparent",
    height: 60,
  }),
  input: (base) => null,
  singleValue: (base) => ({
    ...base,
    border: 0,
    whiteSpace: "normal",
    color: iconTeal,
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: frederikRegular,
    color: iconTeal,
    border: 0,
    fontSize: pxToVw(40),
    whiteSpace: "normal",
  }),
  menu: (base) => ({
    ...base,
    transform: `translate(0, -10px)`,
    borderRadius: 0,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor:
      state.options.map((option) => option.id).indexOf(state.data.id) % 2
        ? "white"
        : "#E4F8F6",
    color: iconTeal,
    ":active": {
      backgroundColor: "none !important",
    },
    cursor: "pointer",
  }),
};

export default function SPLogin() {
  const classes = makeStyles((theme) => selectDistrictStyle(theme))();
  const idps = useSelector((state) => state.idplogins.idps);
  const dispatch = useDispatch();
  useEffect(() => {
    getIDPLogins(dispatch);
  }, []);

  let selectedItem = "";

  return (
    <Grid
      container
      justify={"center"}
      alignItems={"center"}
      className={classes.root}
    >
      <Grid container justify={"center"} alignItems={"center"}>
        <Grid item className={classes.descriptionText}>
          select your district below:
        </Grid>
        <Grid
          container
          item
          justify={"center"}
          alignItems={"center"}
          xs={12}
          className={classes.buttonGrid}
        >
          <Select
            id={"oauth_selector"}
            className={classes.displayText}
            onChange={(selection) => {
              selectedItem = selection.url;
            }}
            styles={oAuthSelectorStyleOverride}
            options={idps.map((option) => option)}
            getOptionLabel={(option) => {
              return `${option.connectionName}`;
            }}
            isSearchable
            placeholder={""}
          />
        </Grid>
        <Grid
          item
          container
          justify={"center"}
          alignItems={"center"}
          className={classes.buttonGrid}
        >
          <button
            type={"submit"}
            className={classes.button}
            style={{ background: iconTeal }}
            onClick={(e) => {
              let relayState = window.location.search;
              relayState = relayState.replace("?relay_state=", "");
              const relayMessage = encodeURIComponent(
                relayState + window.location.hash
              );
              window.location.href = `${selectedItem}?relay=${relayMessage}`;
            }}
          >
            LOG-IN
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}
