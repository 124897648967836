const backgroundBlue = "#013751"; //background
const iconBlue = "#014363"; //icons and type
const iconTeal = "#0FB9A3"; //icons, links, well-being neutral, dashboard background

const standardRed = "#ff6154"; //standard not met
const standardOrange = "#f0a154"; //stand low average
const standardYellow = "#f9d75b"; //standard nearly met
const standardGreen = "#00b487"; //standard met
const standardBlue = "#289ef7"; //standard exceeded

const notebookRed = "#e65825"; //notebook academics, well-being unfavorable
const noteBookGreen = "#b8c433"; //notebook goals
const notebookBlue = "#195da8"; //notebook well-being, well-being favorable
const equityBlue = "#135093"; //Equity Pause Background
const lightGray = "#dddddd"; //Not yet assessed
const darkGrey = "#595D62"; //Student page summary of grades and attendance
const notAssessedGrey = "#DDDDDD"; // Not assessed grey for pie charts.
const white = "#FFFFFF"; //Equity pause text
const rosterGreen = "#8EDED3"; //Roster background

const frederikLight = "Frederik-Light";
const frederikRegular = "Frederik-Regular";
const frederikMedium = "Frederik-Medium";
const frederikDemiBold = "Frederik-DemiBold";
const frederikBold = "Frederik-Bold";

const ibmPlexSans = "IBM Plex Sans";

export {
  backgroundBlue,
  iconBlue,
  iconTeal,
  standardRed,
  standardOrange,
  standardYellow,
  standardGreen,
  standardBlue,
  notebookRed,
  noteBookGreen,
  notebookBlue,
  lightGray,
  darkGrey,
  notAssessedGrey,
  equityBlue,
  white,
  rosterGreen,
  frederikLight,
  frederikMedium,
  frederikRegular,
  frederikDemiBold,
  frederikBold,
  ibmPlexSans,
};
