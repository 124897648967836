import {
  RESET_USER_PERMISSION,
  RESET_USER_PERMISSION_USER_SELECTION,
  SET_USER_PERMISSION_DISTRICT_SELECTION,
  SET_USER_PERMISSION_USER_SELECTION,
  SET_USER_PERMISSIONS,
  UPDATE_USER_PERMISSION_OBJECT,
} from "../../actions/UserPermissions/UserPermissions";

const initialState = {
  selectedDistrict: undefined,
  selectedUser: undefined,
  userPermissionsObject: {},
  userList: [],
};

export default function userPermissions(state = initialState, action) {
  switch (action.type) {
    case SET_USER_PERMISSION_DISTRICT_SELECTION:
      return {
        ...state,
        selectedDistrict: action.payload.selectedDistrict,
        selectedUser: action.payload.selectedUser,
        userList: action.payload.userList,
      };
    case SET_USER_PERMISSION_USER_SELECTION:
      return {
        ...state,
        selectedUser: action.payload.selectedUser,
        userPermissionsObject: action.payload.userPermissionsObject,
      };
    case RESET_USER_PERMISSION:
      return {
        ...state,
        selectedDistrict: undefined,
        selectedUser: undefined,
        userPermissionsObject: {},
        userList: [],
      };
    case RESET_USER_PERMISSION_USER_SELECTION:
      return {
        ...state,
        selectedUser: undefined,
        userPermissionsObject: {},
      };
    case SET_USER_PERMISSIONS:
      return {
        ...state,
        userPermissionsObject: action.payload,
      };
    case UPDATE_USER_PERMISSION_OBJECT:
      return {
        ...state,
        userPermissionsObject: action.payload,
      };
    default:
      return state;
  }
}
