import {
  FETCH_ALL_APPLICATIONS,
  ADD_APPLICATIONS,
} from "../../actions/Applications/Applications";

const initialState = {
  applications: [],
};

export default function applications(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_APPLICATIONS:
      return {
        ...state,
        applications: action.payload,
      };
    case ADD_APPLICATIONS:
      return {
        ...state,
        applications: [...state.applications, action.payload],
      };
    default:
      return state;
  }
}
