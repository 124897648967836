import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { toast } from "react-toastify";
import PasswordValidation, {
  passwordErrors,
} from "../Helpers/Login/PasswordValidation";

//Actions
import { resetPassword, resetCookie } from "../actions/User/User";
import { confirmForgotPassword } from "../actions/Account/ForgotPassword";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword(props) {
  useEffect(() => {
    resetCookie();
  }, []);

  const fromLocal = props.history.location?.state?.from;
  const submitKeyword = fromLocal ? "Login" : "Confirm";
  const token = props.match.params.token;
  const email = props.match.params.email;
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [validationStatus, setValidationStatus] = useState({
    length: false,
    special: false,
    capital: false,
    numeric: false,
    match: false,
  });

  if (!token) {
    window.location.href = "/login";
  }

  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Create New Password
        </Typography>
        <form
          className={classes.form}
          noValidate
          autoComplete={false}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                // onInput={(e) => {
                //     setPassword(e.target.value);
                // }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password1"
                label="Confirm Password"
                type="password"
                id="password1"
                autoComplete="current-password"
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
              />
            </Grid>
            <PasswordValidation
              password1={password}
              password2={password2}
              setValidationStatus={setValidationStatus}
              validationStatus={validationStatus}
            />
          </Grid>
          <Button
            disabled={false}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => {
              const validationErrors = passwordErrors(validationStatus);
              if (validationErrors.length) {
                validationErrors.forEach((e) => {
                  toast.error(e, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                });
              } else if (fromLocal) {
                resetPassword(email, password, token, dispatch);
              } else {
                console.log("Confirm forgot!");
                dispatch(
                  confirmForgotPassword(email, password, token, dispatch)
                );
              }
            }}
          >
            {submitKeyword}
          </Button>
        </form>
      </div>
    </Container>
  );
}
