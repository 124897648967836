import React from "react";
import { Route } from "react-router-dom";
// import Redirect from 'react-router-dom/es/Redirect';
import { Redirect } from "react-router-dom";

export default function ProtectedRoute(props) {
  const {
    adminRequired,
    idpAdminRequired,
    user,
    component: Component,
    ...rest
  } = props;
  if (user.token) {
    if (adminRequired) {
      if (user.eaAdmin) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
      } else {
        return <Redirect to={{ pathname: "/" }} />;
      }
    } else if (idpAdminRequired) {
      if (user.eaAdmin || user.idpAdmin) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
      }
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  window.location.href = "/login";
}
