import axios from "axios";
const API = process.env.REACT_APP_API;

export const ADD_PERMISSIONS_OBJECT = "ADD_PERMISSIONS_OBJECT";
export const UPDATE_PERMISSIONS_OBJECT = "UPDATE_PERMISSIONS_OBJECT";

export async function getDistrictPermissions(dispatch, id) {
  const district_permissions = await axios.post(`${API}/permissions/${id}`);
  console.log(district_permissions.data);
  dispatch({
    type: ADD_PERMISSIONS_OBJECT,
    payload: district_permissions.data,
  });
}

export function updateDistrictPermissions(
  district_id,
  application_id,
  dispatch
) {
  dispatch({
    type: UPDATE_PERMISSIONS_OBJECT,
    payload: {
      application_id: application_id,
      district_id: district_id,
    },
  });
}
