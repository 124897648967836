import React, { Fragment, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { getIDPLogins } from "../actions/IDPLogins/IDPLogins";
import { makeStyles } from "@material-ui/core/styles";
import userPermissionsStyle from "../assets/jss/UserPermissionsStyle";
import {
  resetUserPermission,
  resetUserPermissionUserSelection,
  setUserPermissionDistrictSelection,
  setUserPermissionUserSelection,
  updateUserPermissionObject,
} from "../actions/UserPermissions/UserPermissions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import axios from "axios";
import { toastMessage } from "../Helpers/globalHelpers";
import DialogContentText from "@material-ui/core/DialogContentText";
import AddIcon from "@material-ui/icons/Add";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import { removeUser } from "../actions/ResendingCredentials/ResendingCredentials";
import { toast } from "react-toastify";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import MaterialTable from "material-table";
import Checkbox from "@material-ui/core/Checkbox";
import { AddBox, ArrowDownward } from "@material-ui/icons";

export default function UserPermissions() {
  const API = process.env.REACT_APP_API;
  const classes = makeStyles((theme) => userPermissionsStyle(theme))();
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  let selection = {};
  const emailRegex = /^\S+@\S+\.\S+$/;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);
  const [activeCheckBoxes, setActiveCheckBoxes] = useState(false);
  // const [userList, setUserList] = useState([])
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const {
    user: { user },
    userPermissions: {
      selectedDistrict,
      userList,
      // userPermissionsObject,
      // selectedUser,
    },
    idplogins: { idps, district_ids },
  } = useSelector((state) => state);

  const updateTableData = async () => {
    const headers = {
      AUTHORIZATION: user.token,
    };
    try {
      // const response = await axios.post(`${API}/auth/admin/get_district_users`, {
      //         email: user.email,
      //         districtID: selectedDistrict.district_id,
      //     },{headers: headers})
      // const response_data = await response.data
      // console.log({response_data})
      // setUserList(response_data)
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //     const data = updateTableData()
  // }, []);

  useEffect(() => {
    resetUserPermission(dispatch);
  }, []);

  useEffect(() => {
    getIDPLogins(dispatch);
  }, [user]);

  // useEffect(() => {
  //     updateTableData()
  // }, [selectedDistrict])

  // const [userPermissionsObject, setUserPermissionsObject] = useState({})
  const handleDistrictOnChange = (selectedDistrictInput) => {
    if (selectedDistrictInput === null) {
      resetUserPermission(dispatch);
    } else {
      setUserPermissionDistrictSelection(user, selectedDistrictInput, dispatch);
    }
  };

  const handleUserOnChange = (selectedUserInput) => {
    if (selectedUserInput === null) {
      resetUserPermissionUserSelection(dispatch);
    } else {
      setUserPermissionUserSelection(selectedUserInput, dispatch);
    }
  };

  const handleSwitchChange = (individualPermission) => {
    //updateUserPermissionObject(userPermissionsObject, individualPermission, dispatch);
  };

  const saveSubmit = (key, value) => {
    const districtID = selectedUser?.districtID;
    const email = selectedUser?.email;
    // const {
    //     district_admin,
    //     district_viewer,
    //     eaAdmin,
    //     idpAdmin,
    // } = userPermissionsObject;
    // console.log(userPermissionsObject)
    // const updateObject = {
    //     [district_admin.selectUserKey]: district_admin.booleanValue,
    //     [district_viewer.selectUserKey]: district_viewer.booleanValue,
    //     [eaAdmin.selectUserKey]: eaAdmin.booleanValue,
    //     [idpAdmin.selectUserKey]: idpAdmin.booleanValue,
    // };

    const headers = {
      AUTHORIZATION: user.token,
    };
    const data = axios.post(
      `${API}/auth/admin/update_district_user`,
      {
        userToUpdateEmail: email,
        email: user.email,
        userToUpdateDistrictID: districtID,
        //updateObject: updateObject,
      },
      { headers: headers }
    );
    if (data.status === 200) {
      toastMessage("success", `User ${email} updated successfully!`);
      setUserPermissionDistrictSelection(
        user,
        selectedDistrict,
        dispatch,
        selectedUser
      );
    } else {
      toastMessage("error", "Error: User not updated.");
    }
  };

  const deleteSubmit = async () => {
    const { districtID, email } = selectedUser;

    const headers = {
      AUTHORIZATION: user.token,
    };
    const data = await axios.post(
      `${API}/auth/admin/delete_district_user`,
      {
        userToDeleteEmail: email,
        email: user.email,
        userToDeleteDistrictID: districtID,
      },
      { headers: headers }
    );
    if (data.status === 200) {
      setUserPermissionDistrictSelection(user, selectedDistrict, dispatch);
      resetUserPermissionUserSelection(dispatch);
      toastMessage("success", `User ${email} deleted successfully!`);
    } else {
      toastMessage("error", "Error: User not deleted.");
    }
  };

  const DeleteConfirmation = () => {
    return (
      <Dialog
        open={openDelete}
        onClose={setDeleteDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to permanently delete this user?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Email: {selectedUser.email}
            <br />
            District ID: {selectedUser.districtID}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpenDelete(false);
              deleteSubmit();
            }}
            color="primary"
            autoFocus
          >
            Delete User
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const updateUserSetting = async (
    type,
    userToUpdate,
    districtId,
    newValue
  ) => {
    // setActiveCheckBoxes(true)
    const headers = {
      AUTHORIZATION: user.token,
    };
    try {
      const response = await axios.post(
        `${API}/auth/admin/update_user_permissions`,
        {
          type: type,
          userToUpdate: userToUpdate,
          districtId: districtId,
          newValue: newValue,
        },
        { headers: headers }
      );
    } catch (e) {
      console.log(e);
    }
    // setActiveCheckBoxes(false)
  };

  const CreateUserConfirmation = () => {
    // const {
    //     district_admin,
    //     district_viewer,
    //     eaAdmin,
    //     idpAdmin,
    // } = userPermissionsObject;
    const { district_admin, district_viewer, eaAdmin, idpAdmin } = {};

    const createUserObject = {
      email: selectedUser.email,
      districtID: selectedUser.districtID,
      [district_admin.selectUserKey]: district_admin.booleanValue,
      [district_viewer.selectUserKey]: district_viewer.booleanValue,
      [eaAdmin.selectUserKey]: eaAdmin.booleanValue,
      [idpAdmin.selectUserKey]: idpAdmin.booleanValue,
    };
    return (
      <Dialog
        open={createUserDialogOpen}
        onClose={setCreateUserDialogOpen}
        aria-labelledby="alert-dialog-title-create"
        aria-describedby="alert-dialog-description-create"
      >
        <DialogTitle id="alert-dialog-title-create">
          {"You are about to create the following user:"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Email: {createUserObject.email}
            <br />
            District ID: {createUserObject.districtID}
            <br />
            District Admin: {createUserObject.district_admin.toString()}
            <br />
            EA Admin: {createUserObject.eaAdmin.toString()}
            <br />
            District Viewer: {createUserObject.district_viewer.toString()}
            <br />
            IDP Admin: {createUserObject.idpAdmin.toString()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setCreateUserDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setCreateUserDialogOpen(false);
              createUserOnClick(createUserObject);
            }}
            color="primary"
            autoFocus
          >
            Create User
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const addUserOnClick = () => {
    const tempSelectedUser = {
      email: userInput,
      districtID: selectedDistrict.district_id,
      district_admin: false,
      eaAdmin: false,
      district_viewer: false,
      idpAdmin: false,
    };
    setUserPermissionUserSelection(tempSelectedUser, dispatch);
  };

  const userExists = userList.some((item) => item.email === userInput);

  const createUserOnClick = async (createUserObject) => {
    const headers = {
      AUTHORIZATION: user.token,
    };
    const data = await axios.post(
      `${API}/auth/admin/create_user_from_launcher`,
      createUserObject,
      { headers: headers }
    );

    if (data.status === 200) {
      setUserPermissionDistrictSelection(user, selectedDistrict, dispatch);
      resetUserPermissionUserSelection(dispatch);
      toastMessage(
        "success",
        `User ${createUserObject.email} created successfully!`
      );
    } else {
      toastMessage("error", "Error: User not created.");
    }
  };

  const UserTable = (props) => {
    const { table_data } = props;
    return (
      <MaterialTable
        data={table_data}
        title="Users"
        columns={[
          { title: "email", field: "email" },
          {
            title: "District Admin",
            field: "district_admin",
            render: (rowData) => {
              // console.log({rowData})
              // console.log(rowData.tableData.id)
              return (
                <Checkbox
                  disabled={activeCheckBoxes}
                  onClick={(event) => {
                    updateUserSetting(
                      "district_admin",
                      rowData.email,
                      rowData.districtID,
                      event.target.checked
                    );
                    // userList[rowData.tableData.id].district_admin = event.target.checked
                    // rowData.district_admin = event.target.checked
                  }}
                  defaultChecked={rowData.district_admin}
                />
              );
            },
          },
          {
            title: "District Viewer",
            field: "district_viewer",
            render: (rowData) => {
              JSON.stringify(rowData.district_viewer);
              return (
                <Checkbox
                  onClick={(event) => {
                    updateUserSetting(
                      "district_viewer",
                      rowData.email,
                      rowData.districtID,
                      event.target.checked
                    );
                  }}
                  defaultChecked={rowData.district_viewer}
                />
              );
            },
          },
          {
            title: "EA Admin",
            field: "eaAdmin",
            render: (rowData) => {
              if (/@edanalytics.org\s*$/.test(rowData.email)) {
                return (
                  <Checkbox
                    onClick={(event) => {
                      updateUserSetting(
                        "eaAdmin",
                        rowData.email,
                        rowData.districtID,
                        event.target.checked
                      );
                    }}
                    defaultChecked={rowData.eaAdmin}
                  />
                );
              }
            },
          },
        ]}
        actions={[
          {
            icon: DeleteForeverIcon,
            tooltip: "Delete User",
            onClick: (event, rowData) => {
              const selectedUser = {
                email: rowData.email,
                districtID: rowData.districtID,
              };
              setSelectedUser(selectedUser);
              setOpenDelete(true);
            },
          },
          {
            icon: FingerprintIcon,
            tooltip: "Resend Initial Credentials",
            onClick: (event, rowData) => {
              const selectedUser = {
                email: rowData.email,
                districtID: rowData.districtID,
              };
              removeUser(selectedUser, user);
            },
          },
        ]}
        options={{ actionsColumnIndex: -1 }}
      />
    );
  };

  return (
    <Fragment>
      {openDelete === true ? <DeleteConfirmation /> : ""}
      <Grid container alignItems={"center"} justify={"center"}>
        <Grid item xs={6}>
          <Autocomplete
            className={classes.selectorSubContainer}
            id="user-permission-district"
            options={district_ids}
            getOptionLabel={(option) =>
              `${option.connectionName} - ${option.district_id}`
            }
            onChange={(event, selectedDistrict) => {
              handleDistrictOnChange(selectedDistrict);
            }}
            renderInput={(params) => (
              <TextField {...params} label="District" variant="outlined" />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems={"center"}
        justify={"center"}
        style={{ paddingTop: "1em" }}
      >
        <Grid item xs={8}>
          <UserTable table_data={userList} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
