import React from "react";
import TopAppBar from "./AppBar/TopAppBar";
import TemporaryDrawer from "./Drawer/Drawer";
import Main from "./Main/Main";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cookie from "react-cookies";
import { useDispatch } from "react-redux";
import axios from "axios";
import history from "./history";

const API = process.env.REACT_APP_API;

function App(props: any) {
  const dispatch = useDispatch();
  const paths = history.location.pathname;
  if (paths.includes("reset_password") || paths.includes("token")) {
  } else {
    const user = cookie.load("user") || {
      email: null,
      token: null,
      eaAdmin: false,
      idpAdmin: false,
      districtID: null,
      ssoUser: false,
    };
    dispatch({
      type: "LOG_USER_IN",
      payload: {
        districtID: user.districtID,
        eaAdmin: user.eaAdmin,
        email: user.email,
        token: user.token,
        idpAdmin: user.idpAdmin,
        ssoUser: user.ssoUser,
      },
    });

    if (user.token && !user.ssoUser) {
      const headers = {
        AUTHORIZATION: user.token,
      };
      axios
        .post(
          API + "/auth/verify_token",
          {},
          {
            headers: headers,
          }
        )
        .then((data: any) => {
          switch (data.statusCode) {
            case 200:
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          dispatch({
            type: "LOG_USER_IN",
            payload: {
              email: null,
              token: null,
              eaAdmin: false,
              idpAdmin: false,
              districtID: null,
            },
          });
          cookie.save(
            "user",
            {
              email: null,
              token: null,
              eaAdmin: false,
              idpAdmin: false,
              districtID: null,
            },
            { path: "/" }
          );
          window.location.href = "/login";
        });
    }
  }
  return (
    <div>
      <TopAppBar history={props.history} />
      <TemporaryDrawer />
      <Main history={props.history} />
      <ToastContainer />
    </div>
  );
}

export default App;
