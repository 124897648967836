import { pxToVw, pxToVwTablet } from "../../Helpers/globalHelpers";
import { iconBlue } from "../globalStyles";

const appLauncherStyle = (theme) => ({
  root: {
    maxHeight: "1057px",
    height: pxToVw(1057),
    width: "100%",
    backgroundColor: iconBlue,
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("xl")]: {
      height: 1057,
    },
    [theme.breakpoints.down("md")]: {
      height: pxToVwTablet(1024),
    },
    [theme.breakpoints.down("sm")]: {
      height: 568,
    },
  },
  itemContainer: {
    padding: 20,
    paddingTop: 50,
  },
  emailContainer: {
    color: "white",
    fontSize: 20,
  },
});

export default appLauncherStyle;
