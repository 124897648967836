import axios from "axios";
import { toast } from "react-toastify";
const API = process.env.REACT_APP_API;

export const GET_USERS_FROM_DISTRICT_SELECTION =
  "GET_USERS_FROM_DISTRICT_SELECTION";

export const getUsersFromDistrictSelection = async (
  user,
  selection,
  dispatch
) => {
  const headers = {
    AUTHORIZATION: user.token,
  };
  const users = await axios.post(
    `${API}/auth/admin/get_district_users`,
    {
      email: user.email,
      districtID: selection.district_id,
    },
    { headers: headers }
  );
  if (users) {
    dispatch({
      type: "GET_USERS_FROM_DISTRICT_SELECTION",
      payload: users.data,
    });
  }
};

export async function removeUser(selection, user) {
  const { token } = user;
  const headers = {
    AUTHORIZATION: token,
  };
  try {
    const removedUser = await axios.post(
      API + "/auth/delete_user",
      {
        email: selection.email,
        districtID: selection.districtID,
      },
      { headers: headers }
    );
    if (removedUser) {
      if (removedUser.data.status === "bad") {
        toast.error(removedUser.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        toast.success("Resend Credentials", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
  } catch (error) {
    toast.error("Credentials failed to send", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    console.log(error);
  }
}
