import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

//Components
import ProtectedRoute from "../ProtectedRoutes/ProtectedRoutes";
import AppLauncher from "../AppLauncher/AppLauncher";
import CreateUser from "../CreateUser/CreateUser";
import CreateSSOConnection from "../CreateSSOConnection/CreateSSOConnection";
import AddApp from "../AddApp/AddApp";
import EditApp from "../EditApp/EditApp";
import Login from "../Login/Login";
import SPLogin from "../Login/SPLogin";
import ResetPassword from "../Login/ResetPassword";
import TokenValidation from "../TokenValidation/TokenValidation";
import CreateOauthConnection from "../CreateSSOConnection/CreateOauthConnection";
import AssumeDistrict from "../AssumeDistrict/AssumeDistrict";
import AssumeUser from "../AssumeUser/AssumeUser";
import Permissions from "../Permissions/Permissions";
import CreateNewConnection from "../NewConnection/CreateNewConnection";
import ChangePassword from "../Account/ChangePassword";
import UserPermissions from "../UserPermissions/UserPermissions";

export default function Main(props) {
  const user = useSelector((state) => state.user.user);
  return (
    <div>
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          user={user}
          adminRequired={false}
          idpAdminRequired={false}
          component={AppLauncher}
        />
        <ProtectedRoute
          exact
          path="/new_connection/new_sso_connection"
          user={user}
          adminRequired={false}
          idpAdminRequired={true}
          component={CreateSSOConnection}
        />
        <ProtectedRoute
          exact
          path="/new_connection"
          user={user}
          adminRequired={false}
          idpAdminRequired={true}
          component={CreateNewConnection}
        />
        <ProtectedRoute
          exact
          path="/new_connection/new_oauth_connection"
          user={user}
          adminRequired={false}
          idpAdminRequired={true}
          component={CreateOauthConnection}
        />

        {/* create user admin page */}
        <ProtectedRoute
          exact
          path="/new_user"
          user={user}
          adminRequired={true}
          component={CreateUser}
        />
        <ProtectedRoute
          exact
          path="/new_app"
          user={user}
          adminRequired={true}
          component={AddApp}
        />
        <ProtectedRoute
          exact
          path="/edit_app"
          user={user}
          adminRequired={true}
          component={EditApp}
        />
        <ProtectedRoute
          exact
          path="/assume_district"
          user={user}
          adminRequired={true}
          component={AssumeDistrict}
        />
        <ProtectedRoute
          exact
          path="/assume_user"
          user={user}
          adminRequired={true}
          component={AssumeUser}
        />
        <ProtectedRoute
          exact
          path="/permissions"
          user={user}
          adminRequired={true}
          component={Permissions}
        />
        <ProtectedRoute
          exact
          path="/user_permissions"
          user={user}
          adminRequired={true}
          component={UserPermissions}
        />

        {/* Account Management */}
        <Route
          exact
          path={"/change_password"}
          history={props.history}
          component={ChangePassword}
        />

        {/* Login */}
        <Route exact path={"/login"} component={SPLogin} />
        <Route exact path={"/local_login"} component={Login} />
        <Route
          exact
          path={"/reset_password/:token/:email"}
          location={props.history.location}
          component={ResetPassword}
        />
        <Route
          exact
          path={"/token/:token/:id"}
          history={props.history}
          component={TokenValidation}
        />

        {/* Routes below are for 400-499 pages*/}
        <Route exact path={"/401"} render={() => <div>401 attempt</div>} />
        <Route render={() => <div>404 page</div>} />
      </Switch>
    </div>
  );
}
