import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PasswordValidation, {
  passwordErrors,
} from "../Helpers/Login/PasswordValidation";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changePasswordRequest } from "../actions/Account/ChangePassword";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  buttonParent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "nowrap",
    justify: "space-between",
  },
  button: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(2),
    minWidth: 172,
  },
}));

export default function ChangePassword({ history }) {
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [secondNewPassword, setSecondNewPassword] = useState("");
  const [validationStatus, setValidationStatus] = useState({
    length: false,
    special: false,
    capital: false,
    numeric: false,
    match: false,
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <form
          className={classes.form}
          noValidate
          autoComplete={false}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="oldPassword"
                label="Old Password"
                type="password"
                name="oldPassword"
                autoComplete="Old Password"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  //userItems.email = e.target.value;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="newPassword"
                autoComplete="New Password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="newPasswordAgain"
                label="Verify New Password"
                type="password"
                id="newPasswordAgain"
                autoComplete="New Password"
                onChange={(e) => {
                  setSecondNewPassword(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordValidation
                password1={newPassword}
                password2={secondNewPassword}
                setValidationStatus={setValidationStatus}
                validationStatus={validationStatus}
              />
            </Grid>
          </Grid>
        </form>
        <Grid
          container
          className={classes.buttonParent}
          justify={"space-between"}
        >
          <Grid item>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                const validationErrors = passwordErrors(validationStatus);
                if (!oldPassword.length) {
                  toast.error("Enter your old password.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  });
                } else if (validationErrors.length) {
                  validationErrors.forEach((e) => {
                    toast.error(e, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                    });
                  });
                } else {
                  dispatch(
                    changePasswordRequest({ oldPassword, newPassword }, user)
                  );
                }
              }}
            >
              Change Password
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                history.push("/");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
