import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container/Container";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import axios from "axios";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import history from "../history";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
const API = process.env.REACT_APP_API;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper2: {
    paddingLeft: "2em",
    paddingRight: "2em",
    paddingTop: "1em",
    paddingBottom: "1em",
    marginTop: "1em",
  },
  wrapText: {
    whiteSpace: "normal",
    overflowWrap: "anywhere",
  },
}));

function getSteps() {
  return ["Oauth Settings", "Oauth App Settings", "Create The Connection"];
}

export default function CreateOauthConnection() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.user.user);
  const steps = getSteps();

  const [activeStep, setActiveStep] = useState(0);

  const [spSettings, setSpSettings] = useState({
    uuid: "",
  });

  const [idpSettings, setIdpSettings] = useState({
    oauth_connection_name: "",
    oauth_client_id: "",
    oauth_client_secret: "",
  });

  // axios.post(`${API}/auth/default_connection`,{
  //     districtID: user.districtID,
  //     default_connection_type: connectionType,
  //     email: user.email
  // },{
  //     headers: headers
  // }).then((data) => {
  //     console.log(data)
  //     if(data.data.default_connection === "OAUTH"){
  //         setTypeSelected(CONNECTION_TYPE.OAUTH);
  //         //if (!skipRedirect) history.push("/new_connection/new_oauth_connection")
  //
  //     } else if(data.data.default_connection === "SAML"){
  //         setTypeSelected(CONNECTION_TYPE.SAML);
  //         //if (!skipRedirect) history.push("/new_connection/new_sso_connection")
  //     }
  // }).catch((err) => {
  //     console.log(err)
  // })
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (setDefault = false) => {
    if (setDefault) {
      const headers = {
        AUTHORIZATION: user.token,
      };
      axios
        .post(
          `${API}/auth/default_connection`,
          {
            district_id: user.districtID,
            default_connection_type: "OAUTH",
            email: user.email,
            connection_id: spSettings.uuid,
          },
          {
            headers: headers,
          }
        )
        .then((data) => {
          console.log(data);
          toast.success("Saved as active connection.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setOpen(false);
  };

  useEffect(() => {
    toast.info("Gathering Oauth settings.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    //Get the new idp connection ID to create routes.
    try {
      const headers = {
        AUTHORIZATION: user.token,
      };
      axios
        .post(
          API + "/oauth/init_oauth",
          {
            districtID: user.districtID,
            email: user.email,
          },
          {
            headers: headers,
          }
        )
        .then((res) => {
          const {
            uuid,
            oauth_connection_name,
            oauth_client_id,
            oauth_client_secret,
            createdIDPInit,
          } = res.data.return_items;

          if (createdIDPInit) {
            setSpSettings((prevState) => {
              return {
                ...prevState,
                createdIDPInit: createdIDPInit,
              };
            });
          }

          if (uuid) {
            setSpSettings((prevState) => {
              return {
                ...prevState,
                uuid: uuid,
              };
            });
          }

          if (oauth_connection_name) {
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                oauth_connection_name: oauth_connection_name,
              };
            });
          }

          if (oauth_client_id) {
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                oauth_client_id: oauth_client_id,
              };
            });
          }

          if (oauth_client_secret) {
            setIdpSettings((prevState) => {
              return {
                ...prevState,
                oauth_client_secret: oauth_client_secret,
              };
            });
          }
          toast.success("SP + IDP settings fetched!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  function submitIdpSettings(setAsDefault = false) {
    toast.info("Submitting SP Settings", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    try {
      const headers = {
        AUTHORIZATION: user.token,
      };
      const districtID = user.eaAdmin ? "EA-APP" : user.districtID;
      axios
        .post(
          API + "/oauth/create_connection",
          {
            email: user.email,
            districtID: districtID,
            uuid: spSettings.uuid,
            oauth_connection_name: idpSettings.oauth_connection_name,
            oauth_client_id: idpSettings.oauth_client_id,
            oauth_client_secret: idpSettings.oauth_client_secret,
          },
          {
            headers: headers,
          }
        )
        .then((res) => {
          toast.success("Connection Saved", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        })
        .catch((error) => {
          toast.success(error, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        });

      if (setAsDefault) {
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <div className={classes.paper}>
            <Paper className={classes.paper2} elevation={3}>
              <Typography component="h1" variant="h5">
                Oauth Authorized URIs
                <Tooltip title="Click to copy value to clipboard">
                  <IconButton aria-label="delete">
                    <FileCopyIcon
                      onClick={(e) => {
                        copyCodeToClipboard(
                          `${API}/oauth/callback/${spSettings.uuid}`
                        );
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Typography component="h5" variant="p">
                {API}/oauth/callback/{spSettings.uuid}
              </Typography>
            </Paper>
          </div>
        );
      case 1:
        return (
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              New Oauth Connection <AddIcon />
            </Typography>
            <form
              className={classes.form}
              noValidate
              autoComplete={false}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="oauth_connection_name"
                    variant="outlined"
                    required
                    fullWidth
                    id="oauth_connection_name"
                    label="District Name"
                    defaultValue={idpSettings.oauth_connection_name || ""}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      setIdpSettings((prevState) => {
                        return {
                          ...prevState,
                          oauth_connection_name: new_value,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="oauth_client_id"
                    variant="outlined"
                    required
                    fullWidth
                    id="oauth_client_id"
                    label="Oauth Client ID"
                    defaultValue={idpSettings.oauth_client_id || ""}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      setIdpSettings((prevState) => {
                        return {
                          ...prevState,
                          oauth_client_id: new_value,
                        };
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="idp_singleSignOnService_url"
                    variant="outlined"
                    required
                    fullWidth
                    id="idp_singleSignOnService_url"
                    label="Oauth Client Key"
                    defaultValue={idpSettings.oauth_client_secret || ""}
                    onChange={(e) => {
                      let new_value = e.target.value;
                      setIdpSettings((prevState) => {
                        return {
                          ...prevState,
                          oauth_client_secret: new_value,
                        };
                      });
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        );
      case 2:
        return (
          <div>
            <div className={classes.paper}>
              <Paper className={classes.paper2} elevation={3}>
                <Typography component="h1" variant="h5">
                  Oauth Authorized URIs
                  <Tooltip title="Click to copy value to clipboard">
                    <IconButton aria-label="delete">
                      <FileCopyIcon
                        onClick={(e) => {
                          copyCodeToClipboard(
                            `${API}/oauth/callback/${spSettings.uuid}`
                          );
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Typography component="h5" variant="p">
                  {API}/oauth/callback/{spSettings.uuid}
                </Typography>
              </Paper>
              <Paper className={classes.paper2} elevation={3}>
                <Typography component="h1" variant="h5">
                  District Name
                </Typography>
                <Typography component="h5" variant="p">
                  {idpSettings.oauth_connection_name}
                </Typography>
              </Paper>
              <Paper className={classes.paper2} elevation={3}>
                <Typography component="h1" variant="h5">
                  Oauth Client ID
                </Typography>
                <Typography component="h5" variant="p">
                  {idpSettings.oauth_client_id}
                </Typography>
              </Paper>
              <Paper className={classes.paper2} elevation={3}>
                <Typography component="h1" variant="h5">
                  Oauth Client Key
                </Typography>
                <Typography component="h5" variant="p">
                  {idpSettings.oauth_client_secret}
                </Typography>
              </Paper>
            </div>
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function copyCodeToClipboard(text) {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    toast.success(`Value copied!`, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }
  const redirectToLandingPage = () => {
    history.push("/");
  };
  return (
    <Container component="main" maxWidth="lg">
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={redirectToLandingPage}>Go to launcher</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <Grid item xs={12} md={12}>
                <ButtonGroup
                  fullWidth
                  aria-label="full width outlined button group"
                >
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    disabled={!spSettings.createdIDPInit}
                    color="primary"
                    onClick={() => {
                      if (activeStep === steps.length - 1) {
                        submitIdpSettings();
                      }
                      handleNext();
                    }}
                  >
                    {activeStep === steps.length - 1 ? "SAVE" : "Next"}
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      disabled={!spSettings.createdIDPInit}
                      color="primary"
                      onClick={() => {
                        if (activeStep === steps.length - 1) {
                          submitIdpSettings(true);
                        }
                        handleNext();
                      }}
                    >
                      {activeStep === steps.length - 1
                        ? "Save and set as active connection"
                        : "Next"}
                    </Button>
                  ) : (
                    ""
                  )}
                </ButtonGroup>
              </Grid>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Set as active connection?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Setting as the active connection will default traffic through this
            connection.
            <br />
            Disagree will still save the connection for later use.
            <br />
            Agree will save the connection at set it as the active connection.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button
            onClick={() => {
              handleClose(true);
            }}
            color="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
