import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Link } from "react-router-dom";
import { toggleDrawerState } from "../actions/Menu/Menu";
import appBarStyle from "../assets/jss/appBarStyle";
import { iconTeal } from "../assets/globalStyles";

export default function TopAppBar(props) {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const classes = makeStyles((theme) => appBarStyle(theme))();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const menuOpen = useSelector((state) => state.menu.sideDrawerOpen);
  const isMenuOpen = Boolean(anchorEl);
  //const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const user = useSelector((state) => state.user.user);

  // const handleProfileMenuOpen = event => {
  //     setAnchorEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = event => {
  //     setMobileMoreAnchorEl(event.currentTarget);
  // };

  const menuId = "primary-search-account-menu";
  //const [open, setOpen] = React.useState(false);

  // function handleDrawerOpen() {
  //     setOpen(true);
  // }

  // function handleDrawerClose() {
  //     setOpen(false);
  // }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  //const mobileMenuId = 'primary-search-account-menu-mobile';
  // const renderMobileMenu = (
  //     <Menu
  //         anchorEl={mobileMoreAnchorEl}
  //         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //         id={mobileMenuId}
  //         keepMounted
  //         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //         open={isMobileMenuOpen}
  //         onClose={handleMobileMenuClose}
  //     >
  //         <MenuItem>
  //             <IconButton aria-label="show 4 new mails" color="inherit">
  //                 <Badge badgeContent={4} color="secondary">
  //                     <MailIcon />
  //                 </Badge>
  //             </IconButton>
  //             <p>Messages</p>
  //         </MenuItem>
  //         <MenuItem>
  //             <IconButton aria-label="show 11 new notifications" color="inherit">
  //                 <Badge badgeContent={11} color="secondary">
  //                     <NotificationsIcon />
  //                 </Badge>
  //             </IconButton>
  //             <p>Notifications</p>
  //         </MenuItem>
  //         <MenuItem onClick={handleProfileMenuOpen}>
  //             <IconButton
  //                 aria-label="account of current user"
  //                 aria-controls="primary-search-account-menu"
  //                 aria-haspopup="true"
  //                 color="inherit"
  //             >
  //                 <AccountCircle />
  //             </IconButton>
  //             <p>Profile</p>
  //         </MenuItem>
  //     </Menu>
  // );

  //Dont Render App Bar if on Login page.
  const NoRenderPages = ["login", "local_login", "reset_password"];
  if (NoRenderPages.includes(props.history.location.pathname.split("/")[1])) {
    return <></>;
  }

  const showIconButton = () => {
    if (user.eaAdmin || user.idpAdmin) {
      return (
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            dispatch(toggleDrawerState(!menuOpen));
          }}
        >
          <MenuIcon />
        </IconButton>
      );
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: iconTeal }}>
        <Toolbar>
          {showIconButton()}
          <Link className={classes.noLinkColor} to={"/"}>
            <Typography className={classes.title} variant="h6" noWrap>
              App Launcher
            </Typography>
          </Link>

          {/*<div className={classes.search}>*/}
          {/*<div className={classes.searchIcon}>*/}
          {/*<SearchIcon />*/}
          {/*</div>*/}
          {/*<InputBase*/}
          {/*placeholder="Search…"*/}
          {/*classes={{*/}
          {/*root: classes.inputRoot,*/}
          {/*input: classes.inputInput,*/}
          {/*}}*/}
          {/*inputProps={{ 'aria-label': 'search' }}*/}
          {/*/>*/}
          {/*</div>*/}
          <div className={classes.grow} />
          {/*<div className={classes.sectionDesktop}>*/}
          {/*<IconButton aria-label="show 4 new mails" color="inherit">*/}
          {/*<Badge badgeContent={4} color="secondary">*/}
          {/*<MailIcon />*/}
          {/*</Badge>*/}
          {/*</IconButton>*/}
          {/*<IconButton aria-label="show 17 new notifications" color="inherit">*/}
          {/*<Badge badgeContent={17} color="secondary">*/}
          {/*<NotificationsIcon />*/}
          {/*</Badge>*/}
          {/*</IconButton>*/}
          {/*<IconButton*/}
          {/*edge="end"*/}
          {/*aria-label="account of current user"*/}
          {/*aria-controls={menuId}*/}
          {/*aria-haspopup="true"*/}
          {/*onClick={handleProfileMenuOpen}*/}
          {/*color="inherit"*/}
          {/*>*/}
          {/*<AccountCircle />*/}
          {/*</IconButton>*/}
          {/*</div>*/}
          {/*<div className={classes.sectionMobile}>*/}
          {/*<IconButton*/}
          {/*aria-label="show more"*/}
          {/*aria-controls={mobileMenuId}*/}
          {/*aria-haspopup="true"*/}
          {/*onClick={handleMobileMenuOpen}*/}
          {/*color="inherit"*/}
          {/*>*/}
          {/*<MoreIcon />*/}
          {/*</IconButton>*/}
          {/*</div>*/}
        </Toolbar>
      </AppBar>
      {/*{renderMobileMenu}*/}
      {renderMenu}
    </div>
  );
}
