import axios from "axios";

const API = process.env.REACT_APP_API;

export const FETCH_IDP_LOGINS = "FETCH_IDP_LOGINS";
export const FETCH_ALL_IDS = "FETCH_ALL_IDS";

export function getIDPLogins(dispatch) {
  try {
    axios
      .post(API + "/saml/get_logins")
      .then((res) => {
        let returnArray = [];
        let returnArray_ids = [];

        for (const property in res.data.districts) {
          returnArray_ids.push({
            connectionName: res.data.districts[property].district_name,
            url: res.data.districts[property].id
              ? `${API}/saml/login/${res.data.districts[property].id}`
              : null,
            id: res.data.districts[property].id,
            district_id: res.data.districts[property].district_id,
          });
        }

        res.data.return_items.map((item, index) => {
          return returnArray.push({
            connectionName: item.idp_connection_name,
            url: `${API}/saml/login/${item.id}`,
            id: item.id,
            district_id: item.idp_district_id,
          });
        });

        res.data.OauthItems.map((item, index) => {
          return returnArray.push({
            connectionName: item.oauth_connection_name,
            url: `${API}/oauth/login/${item.id}`,
            id: item.id,
            district_id: item.oauth_district_id,
          });
        });

        const collator = new Intl.Collator("en", {
          numeric: true,
          sensitivity: "base",
        });
        const sorted = returnArray.sort((a, b) =>
          collator.compare(a.connectionName, b.connectionName)
        );
        const sorted_1 = returnArray_ids.sort((a, b) =>
          collator.compare(a.connectionName, b.connectionName)
        );

        //if(res.data.return_items.length > 0){
        dispatch({
          type: "FETCH_IDP_LOGINS",
          payload: sorted,
        });
        dispatch({
          type: "FETCH_ALL_IDS",
          payload: sorted_1,
        });
        //}
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  } catch (error) {
    console.log(error);
  }
}
