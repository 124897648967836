/* eslint-disable */
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import menu from "./reducers/Menu/Menu";
import user from "./reducers/User/User";
import applications from "./reducers/Applications/Applications";
import idplogins from "./reducers/IDPLogins/IDPLogins";
import districts from "./reducers/Districts/Districts";
import permissions from "./reducers/Permissions/Permissions";
import userPermissions from "./reducers/UserPermissions/UserPermissions";
import resendingCredentials from "./reducers/ResendingCredentials/ResendingCredentials";

const reducer = combineReducers({
  menu,
  user,
  applications,
  idplogins,
  districts,
  permissions,
  resendingCredentials,
  userPermissions,
});

const store = createStore(reducer, applyMiddleware(thunk));

//store.subscribe(() => {
//    console.log("State Changed", store.getState())
//});

export default store;
