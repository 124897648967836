import React, { useEffect, Fragment, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import MaterialTable from "material-table";
const API = process.env.REACT_APP_API;
export default function Permissions() {
  const [tableData, setTableData] = React.useState([]);
  const user = useSelector((state) => state.user.user);
  const [activeCheckBoxes, setActiveCheckBoxes] = useState(false);
  const [applications, setApplications] = useState([]);

  const getPermissions = async () => {
    const headers = {
      AUTHORIZATION: user.token,
    };
    try {
      const response = await axios.get(`${API}/permissions/all`, {
        headers: headers,
      });
      const response_data = await response.data;
      setTableData(response_data.connections);
      setApplications(response_data.applications);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const updatePermissions = async (application_id, district_id, add_remove) => {
    const headers = {
      AUTHORIZATION: user.token,
    };
    try {
      const response = await axios.post(
        `${API}/permissions/update`,
        {
          application_id: application_id,
          district_id: district_id,
          add_remove: add_remove ? "add" : "remove",
        },
        { headers: headers }
      );
      const response_data = await response.data;
    } catch (e) {
      console.log({ e });
    }
  };

  const getDynamicCols = () => {
    let return_list = [
      { title: "district_id", field: "district_id" },
      { title: "connection_name", field: "connection_name" },
    ];
    applications.map((item) => {
      return_list.push({
        title: item.applicationName,
        render: (rowData) => {
          let active = false;
          rowData.permissions.map((data) => {
            if (item.id === data.application_id) {
              active = true;
            }
          });
          return (
            <Checkbox
              disabled={activeCheckBoxes}
              onClick={(event) => {
                //TODO need to update the value of the permissions here.
                updatePermissions(item.id, rowData.id, event.target.checked);
              }}
              defaultChecked={active}
            />
          );
        },
      });
    });
    return return_list;
  };
  const PermissionTable = (props) => {
    const { table_data } = props;
    return (
      <MaterialTable
        data={table_data}
        title="Connection Permissions"
        columns={getDynamicCols()}
      />
    );
  };
  return (
    <Fragment>
      <Grid
        container
        alignItems={"center"}
        justify={"center"}
        style={{ paddingTop: "1em" }}
      >
        <Grid item xs={8}>
          <PermissionTable table_data={tableData} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
